<template>
  <div>
    <div
      class="hero-image"
      style="
        margin-top: -1.5rem;
        background-image: url('/images/christmas/header.jpg');
      "
    >
      <div class="hero-text">
        <h1>Book now for Christmas & New Year</h1>
        <h4>Change for Good during the festive period at an Amchara Retreat</h4>
      </div>
    </div>
    <featured-in></featured-in>

    <div class="body-content mx-auto">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 mx-auto text-center">
            <h2 class="green-header-2-text">
              Interested in having a healthier Christmas this year?
            </h2>
            <p>
              Join us at Amchara Somerset for Christmas and the New Year and
              stay healthy all through the festive period.
            </p>
            <p>
              Spaces at our Somerset retreat fill up fast, and we’ve been fully
              booked for the last 2 years.
            </p>
            <p>
              If you’re interested, get in touch soon to book your relaxing
              holiday health retreat.
            </p>
            <div class="row text-center mt-5 mb-5">
              <div class="col-md-3">
                <img
                  src="@/assets/images/xmas/pic-1.jpg"
                  alt="Amchara Christmas Health Retreats"
                  width="100%"
                />
              </div>
              <div class="col-md-3">
                <img
                  src="@/assets/images/xmas/pic-2.jpg"
                  alt="Amchara Christmas Health Retreats"
                  width="100%"
                />
              </div>
              <div class="col-md-3">
                <img
                  src="@/assets/images/xmas/pic-3.jpg"
                  alt="Amchara Christmas Health Retreats"
                  width="100%"
                />
              </div>
              <div class="col-md-3">
                <img
                  src="@/assets/images/xmas/pic-4.jpg"
                  alt="Amchara Christmas Health Retreats"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <h2 class="text-center">
              Complete the enquiry form below and we’ll call you back.
            </h2>
            <p class="text-center">
              <span style="color: red">*</span> Required info.
            </p>
          </div>
          <!-- /col -->
        </div>
        <!-- /row -->
      </div>
      <!-- /container-fluid -->

      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 mt-5 mb-5 mx-auto">
            <enquiry-form
              :source="'christmas_form_2019'"
              :traffic_campaign="traffic_campaign"
              :traffic_source="traffic_source"
            ></enquiry-form>
          </div>
          <!-- /col -->
        </div>
        <!-- /row -->
      </div>
      <!-- /container-fluid -->

      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 mx-auto mb-5">
            <h4 class="darkgreen-text text-center">
              We look forward to helping you plan your perfect health retreat.
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mx-auto mb-3">
            <div class="row mb-2">
              <div class="col-md-6 text-center">
                <div class="green-header-3-text text-center">
                  Amchara Somerset, UK
                </div>
                <img
                  src="@/assets/images/logos/generic/trip-advisor-logo-with-rating.svg"
                  width="221"
                  class="mb-4"
                  alt="TA logo"
                />
              </div>
              <div class="col-md-6 text-center">
                <div class="green-header-3-text text-center">
                  Amchara Gozo, Malta
                </div>
                <img
                  src="@/assets/images/logos/generic/trip-advisor-logo-with-rating.svg"
                  width="221"
                  class="mb-4"
                  alt="TA logo"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- /row -->
      </div>
      <!-- /container-fluid -->

      <div class="container-fluid">
        <div class="mt-2 mb-2">&nbsp;</div>
        <hr width="80%" />
        <div class="mt-2 mb-2">&nbsp;</div>
      </div>
      <!-- /container-fluid -->

      <!--<div class="container-fluid">
        <div class="row">
          <div class="col-md-12 mx-auto mb-5">
            <p class="text-center">
              <img
                src="@/assets/images/logos/social-media/facebook-text-logo-blue.svg"
                width="295"
                alt="FaceBook logo"
              />
            </p>
            <h4 class="text-center facebook-blue-text">RECENT REVIEWS</h4>
          </div>
        </div>
      </div>


      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 mx-auto">
            <div class="row mb-2">
              <div class="col-8">
                <h5 class="facebook-blue-text">
                  <strong>Clare Albrow Harker</strong> 15 May 2018
                </h5>
              </div>
              <div class="col-4 text-right">
                <img src="@/assets/images/icons/facebook-5-star.svg" width="48" alt="FaceBook 5 Stars" />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-8">
                <h5 class="facebook-blue-text">
                  <strong>Moira Bell</strong> 18 April 2018
                </h5>
              </div>
              <div class="col-4 text-right">
                <img src="@/assets/images/icons/facebook-5-star.svg" width="48" alt="FaceBook 5 Stars" />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-8">
                <h5 class="facebook-blue-text">
                  <strong>Kevin Busuttil</strong> 18 January 2018
                </h5>
              </div>
              <div class="col-4 text-right">
                <img src="@/assets/images/icons/facebook-5-star.svg" width="48" alt="FaceBook 5 Stars" />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-8">
                <h5 class="facebook-blue-text">
                  <strong>Aileen Smith</strong> 23 October 2017
                </h5>
              </div>
              <div class="col-4 text-right">
                <img src="@/assets/images/icons/facebook-5-star.svg" width="48" alt="FaceBook 5 Stars" />
              </div>
            </div>

          </div>

        </div>

      </div>
       /container-fluid -->

      <div class="container-fluid">
        <testimonials></testimonials>

        <div class="mb-5">
          <featured-mags></featured-mags>
        </div>
      </div>
      <!-- /container-fluid -->
    </div>
    <!-- /body-content -->

    <newsletter-cta></newsletter-cta>
  </div>
</template>

<script>
import Instagram from "./page-partials/Instagram";
import FeaturedIn from "./page-partials/FeaturedIn";
import Testimonials from "./page-partials/Testimonials";
import NewsletterCta from "./page-partials/NewsletterCta";
import FeaturedMags from "./page-partials/FeaturedMags";
import EnquiryForm from "./page-partials/EnquiryForm";

export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).description,
      // titleTemplate: null,
    };
  },
  data() {
    return {
      traffic_campaign: sessionStorage.getItem('traffic_campaign'),
      traffic_source: sessionStorage.getItem('traffic_source'),
    };
  },
  components: {
    Instagram,
    FeaturedIn,
    FeaturedMags,
    Testimonials,
    NewsletterCta,
    EnquiryForm,
  },
};
</script>

<style>
</style>